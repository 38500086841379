import React, { useEffect, useState } from "react";
import "./login.scss";
import { parseSearch } from "../../core/utils";
import storage from "../../services/storage";
import Screen from "../../components/screen/screen";
import { useNavigate } from "react-router-dom";

const CLIENT_ID = process.env.VIMEO_CLIENT_ID;
const REDIRECT_URL = process.env.VIMEO_REDIRECT_URL;
const AUTH_ENDPOINT = `https://api.vimeo.com/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}&scope=public`;

export default function Login() {
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.search === "") {
      return;
    }
    const { error = null, token = null } = parseSearch(window.location.search);
    window.history.pushState(null, document.title, window.location.pathname);
    setError(error);
    setToken(token);
  }, []);

  useEffect(() => {
    if (token) {
      storage.set("token", token);
      navigate("/admin/dashboard");
    }
  }, [token]);

  return (
    <Screen name="login">
      <h1>Login</h1>
      {error && <div className="error">{error}</div>}
      <a href={AUTH_ENDPOINT}>Login with Vimeo</a>
    </Screen>
  );
}
