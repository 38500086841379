export const qs = (link, object) => {
  let query = "";
  for (const key in object) {
    if (!object.hasOwnProperty(key)) {
      continue;
    }
    if (query !== "") {
      query = `${query}&`;
    }
    query = `${query}${key}=${object[key]}`;
  }
  return `${link}?${query}`;
};

export const parseSearch = (search) => {
  search = search.substring(1);
  try {
    return JSON.parse(
      `{"${decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  } catch {
    return {};
  }
};

export function genCharArray(charA, charZ) {
  var a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0);
  for (; i <= j; ++i) {
    a.push(String.fromCharCode(i));
  }
  return a;
}
